"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reviewStatsFragment = exports.default = void 0;
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _Separator = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/section/Separator"));
var _client = require("@apollo/client");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _Grid = _interopRequireDefault(require("./designsystem/Grid"));
var _FormattedReviewTerm = _interopRequireDefault(require("./FormattedReviewTerm"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const reviewStatsFragment = exports.reviewStatsFragment = (0, _client.gql)`
    fragment ReviewStats on ReviewStats {
        count
        average
        location
        hospitality
        layout
        maintenance
        valueForMoney
    }
`;
const isNumber = some => typeof some === 'number' && some >= 0;
const ReviewStats = ({
  reviewStats: {
    average,
    count,
    location,
    maintenance,
    hospitality,
    valueForMoney,
    layout
  },
  variant = 'dark',
  title
}) => __jsx(_react.default.Fragment, null, title && __jsx(StyledTitle, null, title), isNumber(count) && __jsx(Container, null, __jsx(RatingGrid, null, isNumber(average) && __jsx(Rating, {
  variant: variant
}, __jsx(RatingScore, null, __jsx(_reactIntl.FormattedNumber, {
  value: average,
  style: "decimal"
}))), __jsx(RatingTitle, null, __jsx(Byline, null, __jsx(_react.default.Fragment, null, __jsx(_reactIntl.FormattedNumber, {
  value: count
}), " ", __jsx(_reactIntl.FormattedMessage, {
  id: "YZuOIh",
  defaultMessage: [{
    "type": 0,
    "value": "beoordelingen"
  }]
}))), isNumber(average) && __jsx(ReviewTerm, {
  style: !isNumber(count) ? {
    marginTop: '3.2rem'
  } : undefined
}, __jsx(_FormattedReviewTerm.default, {
  average: average
})))), __jsx(_Grid.default, null, __jsx(Content, null, __jsx(CriteriumContainer, null, isNumber(location) && __jsx(CriteriumCell, null, __jsx(Criterium, null, __jsx(_reactIntl.FormattedMessage, {
  id: "0xAinF",
  defaultMessage: [{
    "type": 0,
    "value": "Ligging"
  }]
})), __jsx(CriteriumRating, null, __jsx(_reactIntl.FormattedNumber, {
  value: location
}))), isNumber(maintenance) && __jsx(CriteriumCell, null, __jsx(Criterium, null, __jsx(_reactIntl.FormattedMessage, {
  id: "OZzOvP",
  defaultMessage: [{
    "type": 0,
    "value": "Onderhoud"
  }]
})), __jsx(CriteriumRating, null, __jsx(_reactIntl.FormattedNumber, {
  value: maintenance
}))), isNumber(hospitality) && __jsx(CriteriumCell, null, __jsx(Criterium, null, __jsx(_reactIntl.FormattedMessage, {
  id: "mb1WDg",
  defaultMessage: [{
    "type": 0,
    "value": "Gastvrijheid"
  }]
})), __jsx(CriteriumRating, null, __jsx(_reactIntl.FormattedNumber, {
  value: hospitality
}))), isNumber(valueForMoney) && __jsx(CriteriumCell, null, __jsx(Criterium, null, __jsx(_reactIntl.FormattedMessage, {
  id: "tv09F4",
  defaultMessage: [{
    "type": 0,
    "value": "Prijs/kwaliteit"
  }]
})), __jsx(CriteriumRating, null, __jsx(_reactIntl.FormattedNumber, {
  value: valueForMoney
}))), isNumber(layout) && __jsx(CriteriumCell, null, __jsx(Criterium, null, __jsx(_reactIntl.FormattedMessage, {
  id: "9y2W8Z",
  defaultMessage: [{
    "type": 0,
    "value": "Inrichting"
  }]
})), __jsx(CriteriumRating, null, __jsx(_reactIntl.FormattedNumber, {
  value: layout
})))), __jsx(ReviewSeparator, null)))));
const ReviewSeparator = (0, _styledComponents.default)(_Separator.default).withConfig({
  displayName: "ReviewStats__ReviewSeparator",
  componentId: "sc-18g6k13-0"
})(["margin:", " 0;"], ({
  theme
}) => theme.spacing['50_Semi']);
const StyledTitle = (0, _styledComponents.default)(_Title.default).attrs({
  variant: 'large',
  elementType: 'h2'
}).withConfig({
  displayName: "ReviewStats__StyledTitle",
  componentId: "sc-18g6k13-1"
})(["margin-bottom:4.8rem;"]);
const Container = _styledComponents.default.div.withConfig({
  displayName: "ReviewStats__Container",
  componentId: "sc-18g6k13-2"
})(["display:flex;flex-direction:column;@media (max-width:", "){> ", "{display:flex;flex-wrap:wrap;}}"], ({
  theme
}) => theme.mediaQueries.s, _Grid.default);
const RatingTitle = _styledComponents.default.div.withConfig({
  displayName: "ReviewStats__RatingTitle",
  componentId: "sc-18g6k13-3"
})(["padding-bottom:2.6rem;grid-column:2 / -1;"]);
const RatingScore = (0, _styledComponents.default)(_Title.default).attrs({
  variant: 'large'
}).withConfig({
  displayName: "ReviewStats__RatingScore",
  componentId: "sc-18g6k13-4"
})(["position:relative;"]);
const Rating = _styledComponents.default.div.withConfig({
  displayName: "ReviewStats__Rating",
  componentId: "sc-18g6k13-5"
})(["display:flex;justify-content:center;position:relative;grid-column:span 1;align-items:center;@media (max-width:", "){min-width:8rem;margin-right:2.82%;}", ";:before{width:100%;content:'';padding-top:100%;border-radius:50%;position:absolute;margin-top:-2%;}"], ({
  theme
}) => theme.mediaQueries.s, ({
  variant,
  theme
}) => variant === 'dark' ? (0, _styledComponents.css)(["::before{background-color:", ";}", "{color:", ";}"], theme.colorAssignments.rating ? (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.rating) : theme.colors.secondary['30'], RatingScore, theme.colors.neutral['0']) : (0, _styledComponents.css)(["::before{box-shadow:inset 0 0 0 2px ", ";background:", ";}", "{color:", ";}"], theme.colors.secondary['0'], theme.colors.neutral['0'], RatingScore, theme.colors.secondary['30']));
const RatingGrid = (0, _styledComponents.default)(_Grid.default).withConfig({
  displayName: "ReviewStats__RatingGrid",
  componentId: "sc-18g6k13-6"
})(["@media (max-width:", "){display:flex;}"], ({
  theme
}) => theme.mediaQueries.s);
const CriteriumRating = (0, _styledComponents.default)(_Title.default).attrs({
  variant: 'small',
  elementType: 'div'
}).withConfig({
  displayName: "ReviewStats__CriteriumRating",
  componentId: "sc-18g6k13-7"
})(["font-weight:500;"]);
const Content = _styledComponents.default.div.withConfig({
  displayName: "ReviewStats__Content",
  componentId: "sc-18g6k13-8"
})(["grid-column:2 / -1;@media (max-width:", "){grid-column:1 / -1;width:100%;margin-top:4rem;}"], ({
  theme
}) => theme.mediaQueries.s);
const Byline = (0, _styledComponents.default)(_Body.default).attrs({
  variant: 'tiny',
  elementType: 'div'
}).withConfig({
  displayName: "ReviewStats__Byline",
  componentId: "sc-18g6k13-9"
})(["margin-bottom:", ";"], ({
  theme
}) => theme.spacing['30_Small']);
const ReviewTerm = (0, _styledComponents.default)(_Title.default).attrs({
  variant: 'large',
  elementType: 'div'
}).withConfig({
  displayName: "ReviewStats__ReviewTerm",
  componentId: "sc-18g6k13-10"
})(["color:", ";font-weight:300;"], ({
  theme
}) => theme.colors.neutral['90']);
const Criterium = (0, _styledComponents.default)(_Body.default).attrs({
  elementType: 'div'
}).withConfig({
  displayName: "ReviewStats__Criterium",
  componentId: "sc-18g6k13-11"
})(["color:", ";font-weight:300;"], ({
  theme
}) => theme.colors.neutral['90']);
const CriteriumCell = _styledComponents.default.div.withConfig({
  displayName: "ReviewStats__CriteriumCell",
  componentId: "sc-18g6k13-12"
})(["display:flex;align-items:center;justify-content:space-between;margin-bottom:0.8rem;grid-column:1 / -1;@media (min-width:", "){grid-column:span 2;}"], ({
  theme
}) => theme.mediaQueries.xs);
const CriteriumContainer = (0, _styledComponents.default)(_Grid.default).withConfig({
  displayName: "ReviewStats__CriteriumContainer",
  componentId: "sc-18g6k13-13"
})(["margin-bottom:3.2rem;"]);
var _default = exports.default = ReviewStats;