"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Title = _interopRequireWildcard(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const TitleWrapper = ({
  title,
  variant = 'large',
  textCentered = false
}) => __jsx(Container, {
  textCentered: textCentered
}, __jsx(_Title.default, {
  variant: variant
}, title));
var _default = exports.default = TitleWrapper;
const Container = _styledComponents.default.div.withConfig({
  displayName: "TitleWrapper__Container",
  componentId: "vrmct0-0"
})(["@media screen and (max-width:", "px){border-bottom:1px solid ", ";margin-bottom:", ";padding-bottom:", ";text-align:", ";}> ", "{max-width:calc(100% - 2.4rem);}"], ({
  theme
}) => theme.mediaQueriesValues.s - 1, ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.spacing['40_Standard'], ({
  textCentered
}) => textCentered ? 'center' : 'initial', _Title.TitleInner);