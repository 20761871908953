"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _Img = _interopRequireDefault(require("./Img"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Label = require("@oberoninternal/travelbase-ds/components/primitive/Label");
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _Separator = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/section/Separator"));
var _Translated = _interopRequireDefault(require("@oberoninternal/travelbase-ds/svg/Translated.svg"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Grid = _interopRequireDefault(require("./designsystem/Grid"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var __jsx = _react.default.createElement;
const Review = ({
  review: {
    average,
    comment,
    title,
    date,
    reply,
    city
  },
  className,
  translationNote,
  ownerName,
  ownerImage,
  hideRating = false
}) => __jsx(Container, {
  className: className
}, __jsx(RatingGrid, null, !hideRating && __jsx(Rating, null, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedNumber, {
  value: average,
  style: "decimal"
}))), __jsx(RatingSummary, null, __jsx(ReviewTitle, null, title), __jsx(Byline, null, city && `${city}, `, __jsx(_reactIntl.FormattedDate, {
  value: new Date(date),
  month: "long",
  year: "numeric"
})))), __jsx(_Grid.default, null, __jsx(Content, null, __jsx(Text, null, comment), translationNote && __jsx(TranslationNote, null, __jsx(_Translated.default, null), __jsx("span", null, translationNote)), reply && __jsx(_react.default.Fragment, null, __jsx(_Separator.default, null), __jsx(Response, null, ownerImage && __jsx(OwnerAvatarWrapper, null, __jsx(_Img.default, (0, _extends2.default)({}, ownerImage, {
  layout: "fill"
}))), __jsx(Reply, null, __jsx(Byline, null, ownerName ? __jsx(_react.default.Fragment, null, __jsx(_reactIntl.FormattedMessage, {
  id: "ESEe97",
  defaultMessage: [{
    "type": 0,
    "value": "Reactie van"
  }]
}), " ", ownerName, ' ') : __jsx(_reactIntl.FormattedMessage, {
  id: "52yRC5",
  defaultMessage: [{
    "type": 0,
    "value": "Reactie gastheer"
  }]
})), __jsx(_Body.default, null, reply)))))));
const Response = _styledComponents.default.div.withConfig({
  displayName: "Review__Response",
  componentId: "sc-1fjacv3-0"
})(["display:flex;> * + *{margin-left:", ";}"], ({
  theme
}) => theme.spacing['40_Standard']);
const Reply = _styledComponents.default.div.withConfig({
  displayName: "Review__Reply",
  componentId: "sc-1fjacv3-1"
})(["img + &{margin-left:", ";}"], ({
  theme
}) => theme.spacing['40_Standard']);
const ReviewTitle = (0, _styledComponents.default)(_Body.default).attrs({
  variant: 'large'
}).withConfig({
  displayName: "Review__ReviewTitle",
  componentId: "sc-1fjacv3-2"
})(["font-weight:700;"]);
const OwnerAvatarWrapper = _styledComponents.default.div.withConfig({
  displayName: "Review__OwnerAvatarWrapper",
  componentId: "sc-1fjacv3-3"
})(["position:relative;overflow:hidden;width:5.2rem;height:5.2rem;border-radius:50%;flex-shrink:0;"]);
const TranslationNote = (0, _styledComponents.default)(_Label.Label).attrs({
  variant: 'small'
}).withConfig({
  displayName: "Review__TranslationNote",
  componentId: "sc-1fjacv3-4"
})(["display:flex;margin-top:2.4rem;align-items:center;font-weight:normal;color:", ";svg + *{margin-left:1.4rem;}"], ({
  theme
}) => theme.colors.neutral['30']);
const Byline = (0, _styledComponents.default)(_Body.default).attrs({
  variant: 'tiny'
}).withConfig({
  displayName: "Review__Byline",
  componentId: "sc-1fjacv3-5"
})(["font-weight:400;"]);
const Container = _styledComponents.default.div.withConfig({
  displayName: "Review__Container",
  componentId: "sc-1fjacv3-6"
})([""]);
const RatingGrid = (0, _styledComponents.default)(_Grid.default).withConfig({
  displayName: "Review__RatingGrid",
  componentId: "sc-1fjacv3-7"
})(["display:flex;@media (min-width:", "){display:grid;}"], ({
  theme
}) => theme.mediaQueries.s);
const Content = _styledComponents.default.div.withConfig({
  displayName: "Review__Content",
  componentId: "sc-1fjacv3-8"
})(["padding-top:", ";grid-column:1 / -1;@media (max-width:", "){width:100%;}@media (min-width:", "){grid-column:2 / -1;}"], ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.mediaQueries.s);
const Text = _styledComponents.default.div.withConfig({
  displayName: "Review__Text",
  componentId: "sc-1fjacv3-9"
})(["* + &{margin-top:2.7rem;}"]);
const Rating = _styledComponents.default.div.withConfig({
  displayName: "Review__Rating",
  componentId: "sc-1fjacv3-10"
})(["border-radius:50%;display:flex;justify-content:center;align-items:center;position:relative;min-width:5.6rem;max-width:5.6rem;margin-right:1.6rem;width:100%;color:", ";grid-column:1;@media (min-width:", "){justify-self:center;margin-right:0;max-width:6.4rem;}:before{width:100%;content:'';padding-top:100%;background-color:", ";border-radius:50%;position:absolute;margin-top:-2%;z-index:-1;opacity:0.05;}"], ({
  theme
}) => theme.colorAssignments.rating ? (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.rating) : theme.colors.secondary['30'], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.colorAssignments.rating ? (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.rating) : theme.colors.secondary['30']);
const RatingSummary = _styledComponents.default.div.withConfig({
  displayName: "Review__RatingSummary",
  componentId: "sc-1fjacv3-11"
})(["grid-column:2 / -1;"]);
var _default = exports.default = Review;